import Vue from 'vue'
import axios from 'axios'

class Api {
  get (url, data, config) {
    return dispatchRequest('get', url, data, config)
  }

  post (url, data, config) {
    return dispatchRequest('post', url, data, config)
  }
}

class Deferred {
  constructor () {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

const ApiService = new Api()

const waiting = []
let refreshing = false

function makeRequest (method, url, data, config) {
  return axios({ method, url, data, ...config })
}

function dispatchRequest (method, url, data, config) {
  if (refreshing) {
    const deferred = new Deferred()

    waiting.push({ deferred, method, url, data, config })

    return deferred.promise
  }

  if (refreshNeeded()) {
    refreshing = true

    return Vue.auth.refresh()
      .then(() => {
        remakeWaiting()

        return makeRequest(method, url, data, config)
      })
      .finally(() => {
        refreshing = false
      })
  } else {
    return makeRequest(method, url, data, config)
  }
}

function remakeWaiting () {
  waiting.forEach((req, i) => {
    makeRequest(req.method, req.url, req.data, req.config)
      .then(response => req.deferred.resolve(response))
      .catch(error => req.deferred.reject(error))

    delete waiting[i]
  })
}

function refreshNeeded () {
  return new Date().getTime() > Vue.auth.user().expiresAt
}

export default ApiService

<template>
  <b-form-group
    v-if="totalRows > options[0]"
    class="float-right"
  >
    <b-form-select
      :value="value"
      :options="options"
      title="Per page"
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'TableLimit',
  props: {
    value: {
      type: Number,
      required: true
    },
    totalRows: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      options: [10, 25, 50, 100, 250, 500]
    }
  }
}
</script>

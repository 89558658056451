<template>
  <b-pagination
    v-if="totalRows > perPage"
    :value="value"
    class="float-left"
    :total-rows="totalRows"
    :per-page="perPage"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'TablePagination',
  props: {
    value: {
      type: Number,
      required: true
    },
    totalRows: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  }
}
</script>

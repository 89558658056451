/**
 * @param error
 * @param {string} prefix
 * @returns {string}
 */
export function getRequestErrorText (error, prefix = 'Request failed') {
  let text = prefix

  if (error.response) {
    const suffix = error.response.data || error.response.statusText

    text = prefix ? `${text}: ${suffix}` : suffix
  }

  return text
}

/**
 * @param {string} code
 * @returns {boolean}
 */
export function isAsinOrUpc (code) {
  return isAsin(code) || /^[0-9]{12}$/.test(code)
}

/**
 * @param {string} code
 * @returns {boolean}
 */
export function isAsin (code) {
  return /^[a-zA-Z0-9]{10}$/.test(code)
}

<template>
  <h1
    v-if="title"
    v-text="title"
  />
</template>

<script>
export default {
  name: 'PageTitle',
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

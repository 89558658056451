<template>
  <div class="clearfix d-sm-block d-md-none mb-2">
    <b-input-group class="mb-2">
      <b-form-select
        v-model="sortBy"
        :options="sortOptions"
        class="w-75"
      >
        <template #first>
          <option value="">
            sort by...
          </option>
        </template>
      </b-form-select>
      <b-form-select
        v-model="sortDesc"
        :disabled="!sortBy"
        class="w-25"
      >
        <option :value="false">
          Asc
        </option>
        <option :value="true">
          Desc
        </option>
      </b-form-select>
    </b-input-group>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TableSortAndFilterMobile',
  props: {
    sortBy: {
      type: String,
      required: true
    },
    sortOptions: {
      type: Array,
      required: true
    },
    sortDesc: {
      type: Boolean,
      required: true
    }
  }
}
</script>

import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'

export default {
  data () {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      fields: [],
      filter: {},
      sortBy: '',
      sortDesc: false
    }
  },
  computed: {
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return f.key
        })
    },
    rows () {
      return this.$refs.table.$refs.itemRows
    }
  },
  watch: {
    filter: {
      handler () {
        this.currentPage = 1
        this.refresh()
      },
      deep: true
    },
    perPage () {
      this.currentPage = 1
    }
  },
  methods: {
    itemsProvider (ctx, callback) {
      let items = []

      const params = {
        currentPage: this.currentPage !== null ? ctx.currentPage : null,
        perPage: this.perPage !== null ? ctx.perPage : null,
        sortBy: ctx.sortBy || null,
        sortDir: ctx.sortBy ? (ctx.sortDesc ? 'desc' : 'asc') : null
      }

      for (const filterParam in this.filter) {
        params[filterParam] = typeof this.filter[filterParam] === 'boolean'
          ? (this.filter[filterParam] !== null ? (this.filter[filterParam] ? 'true' : 'false') : null)
          : this.filter[filterParam] || null
      }

      ApiService.get(ctx.apiUrl, null, {
        params
      })
        .then(response => {
          if (Array.isArray(response.data)) {
            items = response.data
            this.totalRows = response.data.length
          } else {
            items = response.data.items
            this.totalRows = response.data.total
          }

          callback(items)
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'List error',
            variant: 'danger'
          })

          callback(items)
        })

      return null
    },
    refresh () {
      this.$refs.table.refresh()
    },
    selectRow (index) {
      this.$refs.table.selectRow(index)
    },
    unselectRow (index) {
      this.$refs.table.unselectRow(index)
    },
    selectAllRows () {
      this.$refs.table.selectAllRows()
    },
    clearSelected () {
      this.$refs.table.clearSelected()
    }
  }
}

<template>
  <div>
    <div class="float-sm-left">
      <page-title />
    </div>
    <div class="float-sm-right mt-1 mb-2">
      <b-button
        class="mr-2"
        @click="getReport"
      >
        Report
      </b-button>
      <b-dropdown
        split
        dropdown
        @click="$bvModal.show('create')"
      >
        <template #button-content>
          <b-icon icon="plus" />
          Create
        </template>

        <b-dropdown-item v-b-modal.create-batch>
          Batch
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.delete-batch>
          Delete
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <table-sort-mobile
      :sort-by.sync="sortBy"
      :sort-options="sortOptions"
      :sort-desc.sync="sortDesc"
    >
      <b-form-input
        v-model="filter.asin"
        class="mb-2"
        placeholder="Filter by asin"
        debounce="300"
        autofocus
      />
      <b-form-input
        v-model="filter.name"
        class="mb-2"
        placeholder="Filter by name"
        debounce="300"
      />
      <b-form-input
        v-model="filter.comment"
        class="mb-2"
        placeholder="Filter by comment"
        debounce="300"
      />
    </table-sort-mobile>
    <div
      v-if="selected.length"
      class="d-sm-block d-md-none"
    >
      <div class="fixed-bottom mobile-delete-selected-btn">
        <b-button
          variant="danger"
          @click="removeSelected()"
        >
          <b-icon icon="x" />
          ({{ selected.length }})
        </b-button>
      </div>
    </div>
    <b-table
      ref="table"
      :fields="fields"
      :items="itemsProvider"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      api-url="/autocomplete/list"
      stacked="sm"
      show-empty
      no-local-sorting
      selectable
      @row-selected="onRowSelected"
    >
      <template #head(selected)>
        <b-form
          class="mass-actions-form"
          inline
        >
          <b-form-checkbox
            v-model="selectAll"
            button-variant="outline-secondary"
            @change="$event ? selectAllRows() : clearSelected()"
          />
          <b-dropdown
            v-if="selected.length"
            variant="outline-secondary"
          >
            <b-dropdown-item
              variant="danger"
              @click="removeSelected()"
            >
              Delete ({{ selected.length }})
            </b-dropdown-item>
          </b-dropdown>
        </b-form>
      </template>
      <template #head(asin)="data">
        {{ data.label }}
        <b-input
          v-model="filter.asin"
          size="sm"
          debounce="300"
          autofocus
        />
      </template>
      <template #head(name)="data">
        {{ data.label }}
        <b-input
          v-model="filter.name"
          size="sm"
          debounce="300"
        />
      </template>
      <template #head(comment)="data">
        {{ data.label }}
        <b-input
          v-model="filter.comment"
          size="sm"
          debounce="300"
        />
      </template>
      <template #cell(selected)="{ rowSelected, index }">
        <b-form-checkbox
          :checked="rowSelected"
          @change="$event ? selectRow(index) : unselectRow(index)"
        />
      </template>
      <template #cell(actions)="{ item }">
        <b-form
          class="actions-form"
          inline
        >
          <b-button
            class="mr-2"
            size="sm"
            title="Update"
            @click="updateModalShow(item)"
          >
            <b-icon icon="pencil" />
          </b-button>
          <b-button
            size="sm"
            title="Delete"
            variant="danger"
            @click="remove(item.id)"
          >
            <b-icon icon="x" />
          </b-button>
        </b-form>
      </template>
    </b-table>

    <div>
      <table-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
      <table-limit
        v-model="perPage"
        :total-rows="totalRows"
      />
    </div>

    <b-modal
      id="create"
      title="Create record"
      :ok-disabled="!canSubmit(createItem)"
      @ok="submitCreate"
    >
      <b-alert
        :show="!!createError"
        variant="danger"
        v-text="createError"
      />
      <b-form @submit.prevent="create()">
        <b-form-group>
          <b-form-input
            v-model="createItem.asin"
            required
            placeholder="ASIN"
            autofocus
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="createItem.name"
            required
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="createItem.comment"
            placeholder="Comment"
          />
        </b-form-group>
        <input
          type="submit"
          hidden
        >
      </b-form>
      <template #modal-ok>
        <b-spinner
          v-if="creating"
          small
        />
        Create
      </template>
    </b-modal>

    <b-modal
      id="update"
      title="Update record"
      :ok-disabled="!canSubmit(updateItem)"
      @ok="submitUpdate"
    >
      <b-alert
        :show="!!updateError"
        variant="danger"
        v-text="updateError"
      />
      <b-form @submit.prevent="update()">
        <b-form-group>
          <b-form-input
            v-model="updateItem.asin"
            required
            placeholder="ASIN"
            autofocus
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="updateItem.name"
            required
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="updateItem.comment"
            placeholder="Comment"
          />
        </b-form-group>
        <input
          type="submit"
          hidden
        >
      </b-form>
      <template #modal-ok>
        <b-spinner
          v-if="updating"
          small
        />
        Update
      </template>
    </b-modal>

    <b-modal
      id="create-batch"
      title="Batch create records"
      :ok-disabled="!canSubmitCreateBatch"
      @ok="submitCreateBatch"
    >
      <b-alert
        :show="!!createBatchError"
        variant="danger"
        v-text="createBatchError"
      />
      <b-textarea
        v-model="createBatchItems"
        placeholder="asin:name:comment"
        rows="7"
        autofocus
      />
      <template #modal-ok>
        <b-spinner
          v-if="creatingBatchItems"
          small
        />
        Create
      </template>
    </b-modal>

    <b-modal
      id="delete-batch"
      title="Batch delete records"
      :ok-disabled="!canDeleteByAsin(asinToDelete)"
      @ok="deleteByAsin"
    >
      <b-alert
        :show="!!deleteByAsinError"
        variant="danger"
        v-text="deleteByAsinError"
      />
      <b-input
        v-model="asinToDelete"
        placeholder="asin"
        autofocus
      />
      <template #modal-ok>
        <b-spinner
          v-if="deletingByAsin"
          small
        />
        Delete
      </template>
    </b-modal>
  </div>
</template>

<script>
import table from '@/mixins/table'
import PageTitle from '@/components/PageTitle'
import TableSortMobile from '@/components/TableSortMobile'
import TablePagination from '@/components/TablePagination'
import TableLimit from '@/components/TableLimit'
import { getRequestErrorText, isAsin } from '@/utils'
import ApiService from '@/ApiService'

export default {
  name: 'Autocomplete',
  components: {
    PageTitle,
    TablePagination,
    TableLimit,
    TableSortMobile
  },
  mixins: [table],
  data () {
    return {
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'asin',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        { key: 'comment' },
        { key: 'actions' }
      ],
      filter: {
        asin: '',
        name: '',
        comment: ''
      },
      createItem: {
        asin: '',
        name: '',
        comment: ''
      },
      creating: false,
      createError: '',
      updateItem: {},
      updating: false,
      updateError: '',
      selected: [],
      selectAll: false,
      creatingBatchItems: false,
      createBatchItems: '',
      createBatchError: '',
      deleteByAsinError: '',
      asinToDelete: null,
      deletingByAsin: false
    }
  },
  computed: {
    canSubmitCreateBatch () {
      return !!this.parseBatch().length
    }
  },
  watch: {
    createItem: {
      handler () {
        this.createError = ''
      },
      deep: true
    },
    updateItem: {
      handler () {
        this.updateError = ''
      },
      deep: true
    },
    selected () {
      this.selectAll = this.selected.length === this.rows.length
    }
  },
  methods: {
    remove (id) {
      ApiService.post(`/autocomplete/delete?id=${id}`)
        .then(() => {
          this.refresh()
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Delete error',
            variant: 'danger'
          })
        })
    },
    submitCreate (event) {
      event.preventDefault()

      this.create()
    },
    create () {
      this.creating = true
      this.createError = ''

      ApiService.post('/autocomplete', this.createItem)
        .then(() => {
          this.currentPage = 1
          this.createItem = {
            asin: '',
            name: '',
            comment: ''
          }
          this.refresh()
          this.$bvModal.hide('create')
        })
        .catch(error => {
          this.createError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.creating = false
        })
    },
    canSubmit (item) {
      return item.asin && item.name
    },
    updateModalShow (item) {
      this.updateItem = Object.assign({}, item)
      this.$bvModal.show('update')
    },
    submitUpdate (event) {
      event.preventDefault()

      this.update()
    },
    update () {
      this.updating = true
      this.updateError = ''

      ApiService.post(`/autocomplete?id=${this.updateItem.id}`, {
        asin: this.updateItem.asin,
        name: this.updateItem.name,
        comment: this.updateItem.comment
      })
        .then(() => {
          this.refresh()
          this.$bvModal.hide('update')
        })
        .catch(error => {
          this.updateError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.updating = false
        })
    },
    onRowSelected (items) {
      this.selected = items
    },
    removeSelected () {
      ApiService.post('/autocomplete/delete/batch', this.selected.map(i => i.id))
        .then(() => {
          this.refresh()
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Delete selected error',
            variant: 'danger'
          })
        })
    },
    parseBatch () {
      const items = []

      for (const batchRow of this.createBatchItems.split(/\r?\n/)) {
        const batchItem = batchRow.split(':')

        if (batchItem[0] && batchItem[1]) {
          items.push({
            asin: batchItem[0],
            name: batchItem[1],
            comment: batchItem[2] || null
          })
        }
      }

      return items
    },
    submitCreateBatch (event) {
      event.preventDefault()

      this.createBatch()
    },
    createBatch () {
      this.creatingBatchItems = true
      this.createBatchError = ''

      ApiService.post('/autocomplete/batch', this.parseBatch())
        .then(() => {
          this.currentPage = 1
          this.createBatchItems = ''
          this.refresh()
          this.$bvModal.hide('create-batch')
        })
        .catch(error => {
          this.createBatchError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.creatingBatchItems = false
        })
    },
    canDeleteByAsin (asin) {
      return isAsin(asin) && !this.deletingByAsin
    },
    deleteByAsin (event) {
      event.preventDefault()
      this.deleteByAsinError = ''

      ApiService.post('/autocomplete/delete/by-asin', { asin: this.asinToDelete })
        .then((resp) => {
          this.$bvToast.toast(`Удалено строк: ${resp.data}`, {
            title: 'Записи удалены',
            variant: 'success'
          })
          this.refresh()
        })
        .catch(error => {
          this.deleteByAsinError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.asinToDelete = null
          this.deletingByAsin = false
        })
    },
    getReport () {
      ApiService.get('/autocomplete/report', {}, { responseType: 'blob' })
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'report.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.deleteByAsinError = getRequestErrorText(error, '')
        })
    }
  }
}
</script>

<style scoped>
.actions-form {
  min-width: 80px;
}

.mass-actions-form {
  min-width: 60px;
}

.mobile-delete-selected-btn {
  bottom: 5px;
  right: 5px;
  left: unset;
}
</style>
